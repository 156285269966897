/* eslint-disable  @typescript-eslint/no-explicit-any */
const getCommonOptions = (method: string, accessToken?: string, body?: unknown, formData?: unknown) => {
  const options = {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {},
  } as any;
  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  if (formData) {
    options.body = formData;
  } else if (body) {
    options.body = JSON.stringify(body);
    options.headers["Content-Type"] = "application/json";
  }

  return options as RequestInit;
};

export const buildGetOptions = (accessToken?: string) => getCommonOptions("GET", accessToken);

export const buildPostOptions = (accessToken?: string, body?: unknown, formData?: unknown) =>
  getCommonOptions("POST", accessToken, body, formData);

export const buildDeleteOptions = (accessToken?: string) => getCommonOptions("DELETE", accessToken);

export class BackendError extends Error {
  constructor(
    private readonly response: Response,
    public readonly debugMessage?: string,
  ) {
    super(debugMessage);

    Object.setPrototypeOf(this, BackendError.prototype);
  }

  public getResponse(): Response {
    return this.response;
  }
}

export const fetchWithErrorHandling = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  json = true,
  text = false,
): Promise<any> => {
  const res = await fetch(input, init);
  if (!res.ok) {
    let debugMessage;
    try {
      const e = await res.json();
      debugMessage = e.debugMessage;
      console.error(`[BACKEND] ${debugMessage}`, e);
    } catch (e) {
      // ignore
    }
    throw new BackendError(res, debugMessage);
  }

  if (json) {
    return await res.json();
  }

  if (text) {
    return await res.text();
  }

  return res;
};
