import React, { useEffect } from "react";
import { Background } from "../../components/Background";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { authenticationDisabled, codeChallengeKeyPrefix } from "../../services/auth/AuthService";
import { getAuthorizationURL } from "../../services/aws/CognitoService";
import { generateNonce, sha256 } from "../../utils/Security";
import { base64URLEncode } from "../../utils/Base64";
import { useNavigate } from "react-router-dom";
import Routes from "../../components/routing/Routes";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const getLoginUrl = async () => {
      const state = await generateNonce();
      const codeVerifier = await generateNonce();

      sessionStorage.setItem(`${codeChallengeKeyPrefix}-${state}`, codeVerifier);
      const codeChallenge = base64URLEncode(await sha256(codeVerifier));

      return await getAuthorizationURL(state, codeChallenge);
    };

    if (authenticationDisabled()) {
      navigate(Routes.home);
    } else {
      getLoginUrl().then((url) => window.location.replace(url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Background>
      <LoadingOverlay />
    </Background>
  );
};

export default Login;
