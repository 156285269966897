import { createRefresh, useIsAuthenticated as _useIsAuthenticated } from "react-auth-kit";
import { createRefreshParamInterface, RefreshTokenCallbackResponse } from "react-auth-kit/dist/types";
import { getRefreshedTokens } from "../aws/CognitoService";

// prefix for the stored verifier for PKCE code challenge
export const codeChallengeKeyPrefix = "codeVerifier";

// token refresh interval in minutes
const refreshInterval = 10;

export const refreshTokens: createRefreshParamInterface = createRefresh({
  interval: refreshInterval,
  refreshApiCallback: async ({ refreshToken }): Promise<RefreshTokenCallbackResponse> => {
    try {
      const tokens = await getRefreshedTokens(refreshToken || "");
      return {
        isSuccess: true,
        newAuthToken: tokens.access_token,
        newAuthTokenExpireIn: tokens.expires_in,
        newRefreshTokenExpiresIn: tokens.expires_in,
      };
    } catch (error) {
      return {
        newAuthToken: "",
        isSuccess: false,
      };
    }
  },
});

export const authenticationDisabled = () => {
  const topDomain = new URL(document.location.href).hostname.split(".").pop();
  return topDomain === "localhost";
};

export function useIsAuthenticated() {
  const isAuthenticated = _useIsAuthenticated();
  return authenticationDisabled() || isAuthenticated();
}
