import { Background } from "./Background";
import { AppFrame } from "./AppFrame";
import { LoadingOverlay } from "./LoadingOverlay";
import { useIsAuthenticated } from "services/auth/AuthService";

export const AppView = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Background blur="20px" opacity="25%" zIndex={-1} />
      <AppFrame />
      {!isAuthenticated && <LoadingOverlay />}
    </>
  );
};
