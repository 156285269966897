import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "../../components/routing/Routes";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Background } from "../../components/Background";
import { Notify } from "../../components/Notify";
import { useIsAuthenticated } from "../../services/auth/AuthService";
import { AppView } from "components/AppView";

const AdminPanelText = styled.div`
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: xxx-large;
  color: white;
  text-shadow: #000 0px 0px 12px;
  margin-bottom: 24px;
  user-select: none;
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const errorMessage = location.state?.errorMessage;
  let content;

  if (!isAuthenticated) {
    content = (
      <Background>
        <AdminPanelText>Admin Panel</AdminPanelText>
        <Button variant="contained" onClick={() => navigate(Routes.login)}>
          Sign-in
        </Button>
        {errorMessage && <Notify severity="error" message={errorMessage} />}
      </Background>
    );
  } else {
    content = <AppView />;
  }
  return <>{content}</>;
};

export default LandingPage;
